import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';
import { InputLabel as InputLabelBase, LoadingButton } from 'Atoms';

export const Content = styled.div`
  position: absolute;
  top: 12vh;
  text-align: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      top: 5vh;
    }
  `}

  @media screen and (min-height: 600px) and (max-height: 750px) {
    top: 0vh;
  }

  @media screen and (min-height: 751px) and (max-height: 990px) {
    top: 2vh;
  }
`;

export const ContentCf3 = styled.div`
  text-align: center;
`;

export const Header = styled.h1``;

export const InputLabel = styled(InputLabelBase)`
  margin: 0;
  font-size: 1.125rem; /* 18px */
`;

export const NextButton = styled(LoadingButton)`
  ${({ theme }) => css`
    margin-top: 1rem;
    width: 100%;
    background-color: ${theme.colorMappings.CommonplaceBrand};
    border-color: ${theme.colorMappings.CommonplaceBrand};
    border-radius: 0.2rem;
    color: ${theme.colorMappings.white};
    font-size: 1.4rem;

    &:hover {
      color: ${theme.colorMappings.white};
      background-color: ${darken(0.1, theme.colorMappings.CommonplaceBrand)};
      border-color: ${darken(0.1, theme.colorMappings.CommonplaceBrand)};
    }
  `}
`;

export const EmailPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
export const EmailPageContainerCf3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
