import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { captureException } from '@sentry/node';
import { EmailOpenIcon } from 'Icons';
import { TextField } from 'Molecules';
import { GamingText } from 'Organisms';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import {
  parseSyntaxValidationResult,
  validateEmail,
} from 'Client/utils/validators';
import { handleEmailSubmit } from 'Client/services/proposals';
import { syntaxValidationRequest } from 'Client/services/validation';
import { sendConfirmationEmail } from 'Client/services/email';
import { fetchUserLanguage, getBlockedData } from 'Client/services/user';
import { fetchDemographicsByUserByProject } from 'Client/services/demographics';
import {
  MixpanelEventTypes,
  useProject,
  useAnalytics,
  useMap,
  useProposalContext,
} from 'Client/utils/hooks';
import {
  getLocalItem,
  setLocalItem,
  CONFIRMATION_EMAIL_SENT_ITEM,
} from 'Client/utils/localstorage';
import useTrackEmailValidation from 'Client/utils/hooks/useAnalytics/useTrackEmailValidation';
import { changeRouteKeepParams } from 'Client/utils/url';
import { LoadingButtonStates } from 'Atoms';
import LoadingScreen from 'Client/components/molecules/LoadingScreen/LoadingScreen';
import { SupportedLanguages } from 'Client/constants/languages';
import { InputLabel, NextButton } from '../EmailPage.styles';
import { PrivacyPolicyNotice } from './PrivacyPolicyNotice';

export const EmailCapture: React.FC = () => {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { trackEvent } = useAnalytics();
  const trackEmailValidation = useTrackEmailValidation();
  const project = useProject();
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [emailValidationStatus, setEmailValidationStatus] = React.useState<{
    type: 'error' | 'warning';
    message: React.ReactNode;
  } | null>(null);
  const [submitWithForce, setSubmitWithForce] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [{ contributionId, contributionType }, dispatch] = useProposalContext();
  const {
    dispatch: mapDispatch,
    state: { contributionFlow: mapContributionFlow },
  } = useMap();

  const handleEmailInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    setSubmitWithForce(false);
    setEmailValidationStatus(() => null);
    setEmailInputValue(e.target.value?.toLowerCase());
  };

  const saveEmailToContext = (emailValue: string) => {
    if (mapContributionFlow) {
      mapDispatch({
        type: 'SET_COMMENT_DATA',
        payload: { userEmail: emailValue },
      });
    }
    // /confirm-email page checks only one place for the email:
    dispatch({
      type: PROPOSAL_ACTION_TYPES.SET_SIGNUP_EMAIL,
      signupEmail: emailValue,
    });
    // the thanks page doesn't utilise the email info
  };

  const handleNextClick = async () => {
    // Check against the basic email validation regex
    if (!validateEmail(emailInputValue)) {
      return setEmailValidationStatus({
        type: 'error',
        message: t('This does not appear to be a valid email address'),
      });
    }

    setIsSubmitting(true);

    try {
      // since we reach this page only while in the CF flow, all is in the state (no fetch required)
      // gets the id to check for gaming
      const contrId = mapContributionFlow
        ? mapContributionFlow.contributionId
        : contributionId;
      const localStorageUserId = await handleEmailSubmit({
        emailValue: emailInputValue,
        language: i18n.language as SupportedLanguages,
        contributionId: contrId,
        project,
      });

      const { blocked } = await getBlockedData({
        id: localStorageUserId as string,
        email: emailInputValue,
      });
      if (blocked) {
        setEmailValidationStatus({
          type: 'error',
          message: <GamingText />,
        });
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
          isOpen: true,
        });
        //If user is blocked, don't waste resources validating anything else
        return;
      }
      // Unless user wants to force submit in spite of previous warning we call
      // external validation api and handle the result
      const externalValidationStatus = submitWithForce
        ? null
        : await syntaxValidationRequest({
            data: emailInputValue,
          }).then(parseSyntaxValidationResult(t));

      trackEmailValidation(emailInputValue, externalValidationStatus, {
        contributionId: contrId,
      });

      // If there is a result from the validation api call, set the status message
      // and allow user to force submit if type is not 'error'
      if (externalValidationStatus) {
        setSubmitWithForce(externalValidationStatus.type === 'warning');
        setIsSubmitting(false);
        return setEmailValidationStatus(externalValidationStatus);
      }
      setSubmitWithForce(false);
      setEmailValidationStatus(() => null);
      saveEmailToContext(emailInputValue);
      const contrType = mapContributionFlow
        ? mapContributionFlow.type
        : contributionType;
      trackEvent(MixpanelEventTypes.ADDED_EMAIL, {
        path: router.asPath,
      });
      const userLang =
        localStorageUserId && (await fetchUserLanguage(localStorageUserId));
      const confEmailSent = getLocalItem(CONFIRMATION_EMAIL_SENT_ITEM);
      if (confEmailSent && confEmailSent === localStorageUserId) {
        // email is already sent within 30min, do not send again
      } else {
        const res = await sendConfirmationEmail({
          email: emailInputValue,
          contributionId: contrId,
          contributionType: contrType,
          lang: userLang || i18n.language,
        });
        if (res && res?.user && res?.user?._id) {
          setLocalItem(CONFIRMATION_EMAIL_SENT_ITEM, res.user._id);
        }
      }
      const userDemographics = localStorageUserId
        ? await fetchDemographicsByUserByProject(localStorageUserId, project.id)
        : undefined;
      // if user already has demographics, take them straight to /confirm-email
      if (userDemographics && !userDemographics.redirectRequired) {
        const confirmEmailRoute = changeRouteKeepParams(
          router.asPath,
          '/confirm-email'
        );
        router.push(confirmEmailRoute);
      } else {
        const demographicsRoute = changeRouteKeepParams(
          router.asPath,
          '/demographics'
        );
        router.push(demographicsRoute);
      }
    } catch (e) {
      captureException(e);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box mt={3}>
        <EmailOpenIcon />
      </Box>
      {isSubmitting && <LoadingScreen withLoadingRing={true} />}
      <Box mt={2} textAlign="left">
        <InputLabel inputId="email">{t('Email')}</InputLabel>
        <TextField
          data-testid="EmailPage-email-input-field"
          id="email"
          placeholder={t('e.g. sam@smith.com')}
          label={t('E-mail')}
          width="100%"
          type="email"
          status={emailValidationStatus}
          value={emailInputValue}
          handleChange={handleEmailInputChange}
        />
        <PrivacyPolicyNotice />
        <NextButton
          data-testid="EmailPage-next-button"
          onClick={handleNextClick}
          disabled={isSubmitting}
          resetState={() => {}}
          state={
            isSubmitting
              ? LoadingButtonStates.LOADING
              : LoadingButtonStates.INITIAL
          }
        >
          {t('Next')}
        </NextButton>
      </Box>
    </>
  );
};
