import { captureException } from '@sentry/minimal';
import fetch from 'isomorphic-unfetch';
import { User } from 'Shared/types/user';

interface SendConfirmationEmailParams {
  email: string;
  contributionId?: string;
  contributionType: string;
  lang?: string;
  userId?: string; // when user is logged in
  cf3?: boolean;
}

export const sendConfirmationEmail = async ({
  email,
  contributionId,
  contributionType,
  lang = 'en-GB',
  userId = null,
  cf3 = false,
}: SendConfirmationEmailParams): Promise<{ user: User }> => {
  try {
    const res = await fetch(`/api/email/sendConfirmationEmail`, {
      method: 'POST',
      body: JSON.stringify({
        user: { email },
        language: lang,
        contextId: contributionId,
        context: contributionType,
        userId,
        cf3,
      }),
    });
    const json = await res.json();
    return json;
  } catch (err) {
    captureException(
      `Error in sendConfirmationEmail @ sendConfirmationEmail.ts ${err}`
    );
    return null;
  }
};
